import React, { useState } from "react";
import ResetCSS from "../common/assets/css/style";
import { ThemeProvider } from "styled-components";
import CookieConsent from "react-cookie-consent";

import "../styles/typography.scss";
import { theme } from "../../theme";

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "../containers/SecD3v/secd3v.style";

export default function Layout({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <ResetCSS />
      <GlobalStyle />

      <AppWrapper>
        <ContentWrapper>
          {children}
          <CookieConsent
            id="cookieConsent"
            buttonText="Accept"
            overlay
            style={{
              background: "#132636",
              textShadow: "2px 2px black",
            }}
            buttonStyle={{
              background:
                "linear-gradient( 145.76deg,rgb(244,186,79) -94.95%,rgb(244,116,33) 132.3% )",
              color: "white",
              borderRadius: "22.5px",
              fontWeight: "bolder",
            }}
          >
            This website use cookies to improve user experience and analyse
            website traffic.
          </CookieConsent>
        </ContentWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
}
