export const theme = {
  colors: {
    primaryDark: "#3B3F5",
    primaryLight: "#ffffff",
    secondaryLight: "#adbdcc",
    highlightAi: "#80aeff",
    highlightSd: "#09bab5",
    highlightCs: "#FF8030",
    highlightAr: "#FFA72E",
  },
  breakPoints: {
    small: "768px",
    medium: "1024px",
    large: "1366px",
    xlarge: "1920px",
    xxlarge: "2560px",
  },
};
